import * as React from "react";
import { graphql, Link } from "gatsby";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const TOSPage = ({ data }) => {
  const body = data.mdx.body;
  const frontmatter = data.mdx.frontmatter;
  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
      className="dark:bg-gray-900 bg-asbeige h-screen"
    >
      <NavBar bigLogo={false} />
      <Helmet>
        <title>Terms of Service – Anysphere</title>
      </Helmet>
      <div className="w-full grid justify-items-center gap-10 pt-20 px-6 pb-20">
        <div className="grid grid-cols-1 gap-10 lg:gap-20">
          <div className="grid grid-cols-1 gap-4 max-w-7xl border px-6 py-12 rounded-lg hover:shadow-md shadow-sm bg-white">
            <article className="max-w-5xl">
              <h1 className="text-3xl text-center font-bold pb-8 leading-snug dark:text-white">
                {frontmatter.title}
              </h1>
              <p
                className="text-gray-400 pb-8 text-center"
                style={{ color: "#bbb9b5" }}
              >
                Last updated: {frontmatter.updatedDate}
              </p>
              <div className="prose prose-stone prose-lg dark:prose-invert prose-quoteless max-w-none">
                <MDXProvider>
                  <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
              </div>
            </article>
          </div>
        </div>
        <div className="text-asbrown-light pt-20 pb-10">
          Questions? Contact us at{" "}
          <a className="hover:underline" href="mailto:hello@anysphere.co">
            hello@anysphere.co
          </a>
          .
        </div>
      </div>
      <Footer />
    </main>
  );
};

export const query = graphql`
  query TOS_QUERY {
    mdx(slug: { eq: "tos-2938rycjkasckjn34" }) {
      id
      slug
      body
      frontmatter {
        updatedDate(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`;

export default TOSPage;
